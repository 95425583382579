import { useState } from "react"

export default function ImageVideoGallery({ items }) {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const previous = () => {
        if (selectedIndex != 0) {
            setSelectedIndex((pre) => pre - 1);
        }
    }
    const next = () => {
        if (selectedIndex < items.length - 1) {
            setSelectedIndex((pre) => pre + 1);
        }
    }
    return (
        <div className="d-flex flex-column p-2 w-100 image-gallery">
            <div id="carouselExampleIndicators" class="carousel slide ">
                <div class="carousel-inner ">
                    {items.map((item, index) => {
                        return (
                            item.type == "image" ?
                                <div class={`carousel-item ${index == selectedIndex && "active"}`} >
                                    <img src={item.original} class="d-block w-100 h-100 carousel-item-img-video" alt="..."  />
                                </div>
                                :
                                <div class={`carousel-item ${index == selectedIndex && "active"}`} >
                                    <video controls class="d-block w-100 h-100 carousel-item-img-video" >
                                        <source src={item.original} type="video/mp4" />
                                    </video>
                                </div>
                        )
                    }

                    )}

                </div>
                <button class="carousel-control-prev custom-carousel-control" type="button" onClick={previous}>
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next custom-carousel-control" type="button" onClick={next}>
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
            <div>
            <div class="d-flex gap-2 mt-2 px-2 justify-content-md-center overflow-auto" >
                {items.map((item, index) => {

                    return (
                        item.type == "image" ?
                            <img
                                src={item.thumbnail}
                                style={{ width: "80px", height: "100px"}}
                                class={`carousel-indicator-image ${index == selectedIndex && "active"}`} onClick={() => setSelectedIndex(index)}
                            />
                            :
                            <img
                                src={item.thumbnail}
                                style={{ width: "80px", height: "100px" }}
                                class={`carousel-indicator-image ${index == selectedIndex && "active"}`} onClick={() => setSelectedIndex(index)}
                            />
                    )

                }
                )}
            </div>
            </div>
        </div>
    )
}